.urlInput {
  margin: auto;
  width: 50%;
  margin-top: 3rem;
  display: flex;
}

.hint {
  margin: auto;
  width: 50%;
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.5);
}

.patience {
  margin: 1rem auto auto;
  width: 50%;
  color: #1976d2;
}

.URL {
  width: 750px;
  margin-right: 1rem !important;
}

.error {
  width: 50%;
  color: #d32f2f;
  margin: 1rem auto auto;
}

.status-outer {
  margin-top: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.status-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.items {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.item {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  align-items: center;
}

.left {
  grid-area: 1/1/2/2;
}

.right {
  grid-area: 1/2/2/3;
}

.name {
  margin-left: 0.5rem !important;
}

.button-success {
  background-color: #28a745 !important;
}

.button-failure {
  background-color: #dc3545 !important;
}
